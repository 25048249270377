import ErrorReport from '@components/ui/ErrorReport';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import type { ActionFunctionArgs, LoaderFunctionArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import { Form, Link, useLoaderData } from '@remix-run/react';
import { authenticator } from '@services/auth.server';
import { commitDataSession, getDataSession } from '@services/cookie.server';
import { commitSession, getSession } from '@services/session.server';
import { useState, useEffect } from 'react';

export const meta = () => [{ title: 'Login | vIP (Virtual In-Processing)' }];

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, {
    successRedirect: '/checklist',
  });
  const session = await getSession(request.headers.get('cookie'));
  const error = session.get(authenticator.sessionErrorKey);

  const dataSession = await getDataSession(request.headers.get('Cookie'));
  const message = dataSession.get('message') || null;

  let headers = new Headers();
  headers.append('Set-Cookie', await commitSession(session));
  headers.append('Set-Cookie', await commitDataSession(dataSession));

  return json(
    { error, message },
    {
      headers,
    }
  );
}

export async function action({ request, context }: ActionFunctionArgs) {
  return await authenticator.authenticate('user-pass', request, {
    successRedirect: '/checklist',
    failureRedirect: '/login',
    throwOnError: true,
    context,
  });
}

const LoginForm = () => {
  const loaderData = useLoaderData<typeof loader>();

  const [showPassword, setShowPassword] = useState(false);
  const [animationTrigger, setAnimationTrigger] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    if (loaderData?.error) {
      setAnimationTrigger(false);
      requestAnimationFrame(() => {
        setAnimationTrigger(true);
      });
    }
  }, [loaderData?.error]);

  return (
    <>
      <div className="relative flex overflow-hidden h-full">
        <div className="absolute inset-0 flex justify-center items-center flex-col gap-y-5">
          <div className="flex items-center text-center justify-center">
            {loaderData?.error && (
              <div
                className={`absolute rounded-2xl px-4 text-center bg-red-700 text-slate-100 ${
                  animationTrigger ? 'animate-shake' : ''
                }`}>
                {loaderData?.error?.message}
              </div>
            )}
            {loaderData?.message && (
              <div className="absolute rounded-2xl text-center bg-green-700 text-slate-100">
                {loaderData?.message}
              </div>
            )}
          </div>

          <div className="p-10 w-[450px] flex-shrink-0 flex flex-col justify-between bg-white">
            <div className="flex justify-between ml-14 mr-14 mb-7">
              <img
                className="w-50 h-20"
                src="/airforce.png"
                alt="United States Air Force"/>
              <img
                className="w-20 h-20"
                src="/phoenix.png"
                alt="Travis AFB Phoenix Spark"/>
            </div>

            <Form method="post">
              <div className="mb-5">
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-gray-900">
                  Email address
                </label>
                <input
                  name="email"
                  type="email"
                  autoComplete="email"
                  className="block w-full border-1 border-gray-800 py-1.5 focus:outline-none focus:ring-0 focus:border-gray-800 text-gray-900 shadow-sm placeholder:text-gray-400"/>
              </div>

              <div className="mb-5">
                <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                  Password
                </label>
                <div className="relative">
                  <input
                    name="password"
                    type={showPassword ? 'text' : 'password'}
                    autoComplete="current-password"
                    className="block w-full border-1 border-gray-800 py-1.5 focus:outline-none focus:ring-0 focus:border-gray-800 text-gray-900 shadow-sm placeholder:text-gray-400"/>
                  <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-600">
                    {showPassword ? <EyeSlashIcon className="size-6" /> : <EyeIcon className="size-6" />}
                  </button>
                </div>
              </div>

              <button
                type="submit"
                className="w-full justify-center bg-gray-800 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
                Sign In
              </button>
              <p className="mt-10 text-center text-sm text-gray-500">
                Not a member?
                <Link
                  to="/register"
                  className="font-semibold leading-6 text-gray-800 hover:text-gray-800">
                  {' '}
                  Register
                </Link>
              </p>
              <div className="mt-1 text-center text-sm text-gray-500">
                <p>Have feedback, an issue, or want to request a demo?</p>
                <Link
                  to="https://travisspark.atlassian.net/servicedesk/customer/portal/4"
                  rel="noreferrer"
                  target="_blank"
                  className="font-semibold leading-6 text-gray-800 hover:text-gray-800">
                  Contact us here
                </Link>
              </div>
            </Form>
          </div>
        </div>

        <div className="overflow-hidden h-full">
          <img
            className="object-cover object-center h-screen w-screen"
            src="/f-35-twin.jpeg"
            alt="Fighter Aircraft"/>
        </div>
      </div>
    </>
  );
};

export const ErrorBoundary = ErrorReport;
export default LoginForm;
